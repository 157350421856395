<template>
    <div>
        <div class="nav-bar--placeholder">
            <van-nav-bar
                title="我的房间"
                fixed
                :left-arrow="false"
                @click-left="onClickLeft"
            />
        </div>
        <div>
            <div v-show="loading" class="flex-row flex-center">
                <van-loading />
            </div>
            <div v-if="!roomList.length" class="empty-data">您还没有入住的房间</div>

            <div class="od-room__item" v-for="item of roomList" :key="item.hotelRoomId">
                <div class="flex-item flex-row">
                    <div class="od-room__body">
                        <div>酒店名称：{{ item.hotelBranchName }}</div>
                        <div>在住房号：{{ item.hotelRoomNumber }}</div>
                    </div>
                </div>
                <div class="flex-row flex-center od-room__open" @click="onControl(item)">
                    <span>控制</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "MyRooms",
    data() {
        return {
            loading: false,
            roomList: []
        }
    },
    methods: {
        onClickLeft() {
            this.$router.back()
        },
        onControl(data) {
            this.$router.push({
                "path": '/room-control',
                "query": {
                    "roomId": data.hotelRoomId,
                    "hotelId": data.hotelBranchId
                }
            })
        },
        getRooms() {
            this.loading = true
            this.$axios.get('/api/weChatMini/v1/member/checkInList').then(res => {
                this.loading = false
                this.roomList = res.list || []
            }).catch(err => {
                this.loading = false
                this.roomList = []
            })
        }
    },
    mounted() {
        this.getRooms()
    }
}
</script>

<style scoped>
.od-room__caption {
    padding: 0.14rem 0.14rem 0.07rem;
    color: #666;
}

.od-room__item {
    display: -webkit-flex;
    display: flex;
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.od-room__item > div {
    padding: 0.14rem;
}

.od-room__open {
    position: relative;
}

.od-room__open::before {
    content: "";
    position: absolute;
    left: 0;
    top: 10%;
    height: 80%;
    width: 1px;
    background-color: #f8f8f8;
}

.od-room__open:active {
    background-color: #f8f8f8;
}

.od-room__image {
    display: block;
}

.od-room__body {
    -webkit-box-flex: 1;
    flex: 1;
    padding-left: 0.1rem;
}
</style>